.wilab-result-wrapper {

}
.wilab-result {
    background: #fff;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
    min-height: 82px;
}

.wilab-summary-success {
    background-color: #b7eb8f; /* green-3 */
}

.wilab-summary-warning {
    background-color: #ffd591; /* orange-3 */
}

.wilab-summary-failure {
    background-color: #ffbb96; /* volcano-3*/
}

.wilab-summary-cancelled {
    background-color: #91d5ff; /* blue-3 */
}

.extra-data {
    font-family: monospace;
}

.wilab-chart{
    height: 16px;
    text-align: center;
    color: white;
    font-size: 0.8em;
    min-width: 16px;
    transition: height .3s, line-height .3s;
}

.wilab-chart:hover {
    height: 24px;
    line-height: 24px;
}

.wilab-chart-success {
    background-color: #73d13d;
}
.wilab-chart-success:hover {
    background-color: #389e0d;
}

.wilab-chart-warning {
    background-color: #ffa940;
}
.wilab-chart-warning:hover {
    background-color: #fa8c16;

}

.wilab-chart-failure {
    background-color: #fa541c;
}
.wilab-chart-failure:hover {
    background-color: #d4380d;
}
.finterop-testsuite-table {
}

.finterop-testsuite-table th {
    background-color: #f5f5f5;
 /*   color: white; */
    text-align: center;
    border: 1px solid #e8e8e8;

  /*  max-width: 170px;*/
}

.finterop-testsuite-table td{
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border: 1px solid #e8e8e8;
    text-align: center;
    color: white;
    min-width: 80px;
}
.finterop-testsuite-table td a{
    color: white;
}

.finterop-testsuite-table th:not(:first-child) {
    font-family: monospace, monospace;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.finterop-testsuite-table td:first-child{
    padding-left: 1em;
    padding-right: 1em;
    color: #595959;
    font-family: monospace, monospace;
}


.verdict-pass {
    background-color: #52c41a;
}

.verdict-inconclusive {
    background-color: #fa8c16;
}
.verdict-fail {
    background-color: #fa541c;
}

.verdict-error {
    background-color: #f5222d;
}

.verdict-None {
    background-color: #1890ff;
}

.finterop-testsuite-table tr:hover td:first-child{
    background-color: #e8e8e8; /* grey-4 */
}

.finterop-testsuite-table tr:hover td.verdict-pass{
    background-color: #389e0d;
}

.finterop-testsuite-table tr:hover td.verdict-inconclusive {
    background-color: #d46b08;
}
.finterop-testsuite-table tr:hover td.verdict-fail {
    background-color: #d4380d;
}

.finterop-testsuite-table tr:hover td.verdict-error {
    background-color: #cf1322;
}

.finterop-testsuite-table tr:hover td.verdict-None {
    background-color: #096dd9;
}

.status-detail-success {
    /*border: 1px solid #b7eb8f;*/
    /*background-color: #f6ffed;*/
    color: #52c41a;
}
.status-detail-error {
    /*border: 1px solid #ffa39e;*/
    /*background-color: #fff1f0;*/
    color: #f5222d;
}
.status-detail-warning {
    /*border: 1px solid #ffe58f;*/
    /*background-color: #fffbe6;*/
    color: #faad14;
}

.logline {
    display: flex;
}

.logline-timestamp {
    font-family: monospace;
    width: 120px;
    margin-right: 8px;
    text-align: right;
    flex-shrink: 0;
}

.logline-type {
    font-family: monospace;
    width: 96px;
    margin-right: 8px;
    text-align: right;
    flex-shrink: 0;
}

.logline-text {
    flex-shrink: 1;
}

/*.logline-row-note{*/
    /*background-color: #e6f7ff;*/
/*}*/

.logline-row-exception{
    background-color: #fff2e8;
}


.logline-row-warn{
    background-color: #fff7e6;
}
@import '~antd/dist/antd.css';
@import '~ant-design-pro/dist/ant-design-pro.css';

.content-wrapper {
    background-color: #fff;
    margin: 24px;
    padding: 24px;
    height: 100%;
    min-height: 280px;

}

.page-with-breadcrumb-wrapper {
    padding: 0 24px 24px;
}

.breadcrumb-wrapper {
    margin: 16px 0;
}

.content-after-breadcrumb {
    background-color: #fff;
    margin: 0;
    padding: 24px;
    height: 100%;
}

.overview-menu {
    height: 100%;
    border-right: 0;
}

.overviewmap-menu .overview-menuitem {
    padding-right: 10px;
}

.overview-menuitem {
    display: flex;
    align-items: center;
}

.overview-menuitem-name {
    flex: 1 0 auto;
}

.overview-menuitem-switch {

}


.category-wrapper {
    background-color: #fff;
    margin: 24px;
    padding: 24px;
}
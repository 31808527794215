
.loglines-table table {
    table-layout: fixed;
}

.loglines-table td{
    vertical-align: top;
}


/*.logline-row-note{*/
/*background-color: #e6f7ff;*/
/*}*/

.logline-row-exception {
    background-color: #fff2e8;
}

.logline-row-warn {
    background-color: #fff7e6;
}
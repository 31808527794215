.testbed-logo {
    height:100%;
    display: flex;
    align-items:center;
    justify-content: center;
}

.testbed-logo img {
    max-width: 75px;
    max-height: 75px;
}

.testbed-logo .placeholder {
    filter: grayscale(100%) opacity(50%);

}

.action-item{
    padding-right: 1em;
}

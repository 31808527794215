
.ansible-part {
    font-family: monospace;
    white-space: pre-wrap;
}

.other-part {
    font-family: monospace;
    color: #8c8c8c;
    white-space: pre-wrap;
}


.ansible-output {
    white-space: pre;
    font-size: small;
}

.parts-anchor{
    padding-top: 24px;
    margin-left: 24px;
    background-color: #f0f2f5;
}


.parts-anchor .ant-anchor-link-title {
    overflow: visible;
}

/*body {*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*font-family: sans-serif;*/
  /*!*background-color: #f2f2f2;*!*/
/*}*/

/*.navbar {*/
  /*margin-bottom: 0;*/
/*}*/

.selftest-wrapper{
    margin-top: 2em;
    margin-left: 5%;
    margin-right: 5%;
}

.selftest-alert-content{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: row;
}

.selftest-moreinfo {
    margin-top: 1em;
}

.selftest-status{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
}
.selftest-status-icon {
    width: 55px;
    animation: pulsate 1s infinite alternate;
    margin-bottom: 10px;
}

.selftest-alert-content .selftest-status-icon {
    width: 30px;
    margin-right: 10px;
}

.selftest-info {
    text-align:center;
}

@keyframes pulsate {
    to {
        transform: scale(0.9);
    }
}

.success .selftest-status-icon{
    fill: #52c41a; /*green-6 */
}

.error .selftest-status-icon{
    fill: #fa541c; /*volcano-6*/
}

.warning .selftest-status-icon{
    fill: #fa8c16; /*orange-6 */
}

.unavailable .selftest-status-icon{
    fill: #bfbfbf /* grey-6 */;
}

.selftest-header-status{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: row;
}
.selftest-status-icon {
    width: 55px;
    animation: pulsate 1s infinite alternate;
    margin-bottom: 10px;
}

.selftest-header-status > .selftest-status-icon{
    width: 40px;
}

.selftest-header-info {
    margin-left: 8px;
}

.selftest-header-info {
    color: rgba(255, 255, 255, 0.65);
}

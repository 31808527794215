.logline {
    display: flex;
}

.logline-timestamp {
    font-family: monospace;
    width: 120px;
    margin-right: 8px;
    text-align: right;
    flex-shrink: 0;
}

.logline-type {
    font-family: monospace;
    width: 96px;
    margin-right: 8px;
    text-align: right;
    flex-shrink: 0;
}

.logline-text {
    flex-shrink: 1;
}

.call-prettify {
    margin-left: 48px;
    font-weight: 400;
    font-size: smaller;
}

.call-content {
    padding: 8px;
    border: #e8e8e8 1px solid;
    white-space: pre-wrap;
    font-size: small;
    min-height: 3em;
}

.call-content-empty {
    /* background: repeating-linear-gradient(45deg, #fafafa, #fafafa 10px, #f5f5f5 10px, #f5f5f5 20px);*/
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #fafafa 10px, #fafafa 20px);
}

.call-info {
    display: flex;

}

.call-info-name {
    font-weight: bold;
    width: 180px;
    margin-right: 8px;
    text-align: right;
}

.call-info-value {
    font-family: monospace;
}

.geni-response-description {
    margin-bottom: 12px;
    font-size: smaller;
}

.apicalls-anchor .ant-anchor-link-title {
    overflow: visible;
}

.apicalls-anchor {
    padding-top: 24px;
    margin-left: 24px;
    background-color: #f0f2f5;
}

.description {
    margin-bottom: 16px;
}

.description-title {
    font-weight: bold;

}

.code-description .description-value {
    font-family: monospace;
    word-break: break-word;
}

.test-steps .ant-steps-item-title {
    font-weight: 500;

}

.test-steps .ant-steps-item-title a {
    color: #262626;

}

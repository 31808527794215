.testinstance-results-table {
    max-width: 900px;
}

.testresult-summary-success {
    background-color: #b7eb8f; /* green-3 */
}

.testresult-summary-warning {
    background-color: #ffd591; /* orange-3 */
}

.testresult-summary-failure {
    background-color: #ffbb96; /* volcano-3*/
}

.testresult-summary-cancelled {
    background-color: #91d5ff; /* blue-3 */
}

.testinstancehistory-actions {
    list-style: none;
    padding: 0;
}

/*.testinstancehistory-actions:before,*/
/*.testinstancehistory-actions:after {*/
/*content: "";*/
/*display: table;*/
/*}*/

.testinstancehistory-actions > li {
    float: left;
    /* text-align: center;*/
    padding-right: 12px;
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.45);
}

.testinstancehistory-actions > li:not(:last-child){
    border-right: 1px solid #bfbfbf;
}

.testinstancehistory-actions > li > span {
    /*  display: inline-block;*/

    font-size: 14px;
    /*    cursor: pointer;*/
    /*   line-height: 22px;*/
    /*  min-width: 32px;*/
    /*   position: relative;*/
}

.testinstancehistory-actions > li > span:hover {
    color: #1890ff;
    -webkit-transition: color .3s;
    transition: color .3s;
}

.testinstancehistory-actions > li > span a {
    /*color: rgba(0, 0, 0, 0.45);*/
    line-height: 22px;
    display: inline-block;
    /*  width: 100%;*/
}
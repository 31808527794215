.description{
    margin-bottom: 16px;
}
.description-title {
    font-weight: bold;

}

.code-description .description-value {
    font-family: monospace;
    word-break: break-word;
}

.test-steps .ant-steps-item-title {
    font-weight: 500;

}

.test-steps .ant-steps-item-title a {
    color: #262626;

}
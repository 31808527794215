.icon-header-image {
    font-size: 400%;
}

.banner .img-thumbnail {
    position: relative;
    width: 165px;
    height: 165px;
}

.banner img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 155px;
}

.resultdetail-header .antd-pro-page-header-content {
    flex-basis: 600px;
}

.resultdetail-header .antd-pro-page-header-extraContent {
    flex-basis: 300px;
    flex-grow: 1;

    margin-left: 10px;
}

.steps-timeline a {
    color: #262626;
}

.result-tab-image {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    vertical-align: text-bottom;
    filter: grayscale(90%);
}

.ant-tabs-tab-active .result-tab-image {
    filter: none;
}